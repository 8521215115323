<template>
    <el-dialog width="600px" top="40px" title="Updatera" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false" @open="modalOpened" @closed="resetModalState">
        <el-form ref="form" :model="formData" class="px-16" :rules="rules" v-loading="$waiting.is('modal.loading')">
            <el-form-item label="Nummer" prop="number">
                <el-input v-model="formData.number" />
            </el-form-item>
            <el-form-item label="Namn" prop="name">
                <el-input v-model="formData.name" />
            </el-form-item>
            <el-form-item label="Kontotyp" prop="accountType">
                <el-select v-model="formData.accountType" class="w-full">
                    <el-option v-for="item in accountsPlanTypes" :key="item" :value="item" :label="translate(item)" />
                </el-select>
            </el-form-item>
            <el-form-item label="Reskontra" prop="subLedgerType">
                <el-select v-model="formData.subLedgerType" class="w-full">
                    <el-option v-for="item in subLedgerTypes" :key="item" :value="item" :label="translateSubLedger(item)" />
                </el-select>
            </el-form-item>
            <el-form-item label="Periodisering tillgångskonto" prop="interimAssetAccount">
                <el-input v-model="formData.interimAssetAccount" />
            </el-form-item>
            <el-form-item label="Periodisering skuldkonto" prop="interimDebtAccount">
                <el-input v-model="formData.interimDebtAccount" />
            </el-form-item>
            <el-form-item label="SRU">
                <el-input v-model="formData.sru" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="default">Stäng</el-button>
            <el-button @click="update" :loading="$waiting.is('updating')" type="primary">Spara</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Api from "./accountPlans.api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        selectedItemId: {
            type: Number,
            default: null,
        },
        selectedPlanId: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            formData: {},
            accountsPlanTypes: [],
            subLedgerTypes: [],
            rules: {
                number: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                asset: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                interimAssetAccount: [{ required: false, message: "Nödvändig", trigger: "blur" }],
                interimDebtAccount: [{ required: false, message: "Nödvändig", trigger: "blur" }],
                accountType: [{ required: true, message: "Nödvändig", trigger: "blur" }],
            },
        };
    },

    async created() {
        this.accountsPlanTypes = await Api.getAccountPlansTypes();
        this.subLedgerTypes = await Api.getSubLedgerTypes();
    },

    methods: {
        modalOpened() {
            this.$waiting.start("modal.loading");
            this.getAccountPlansTypes();
            this.getDetails();
        },

        async getAccountPlansTypes() {
            this.accountsPlanTypes = await Api.getAccountPlansTypes();
        },

        async getDetails() {
            this.formData = await Api.getDetails({ planId: this.selectedPlanId, itemId: this.selectedItemId });
            this.$waiting.end("modal.loading");
        },

        async update() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            this.$waiting.start("updating");
            await Api.updateItem({ planId: this.selectedPlanId, itemId: this.selectedItemId, formData: this.formData });
            this.$notify.success({ title: "Utfört" });
            this.$emit("refresh");
            this.$waiting.end("updating");
            this.closeModal();
        },
        resetModalState() {},
        closeModal() {
            this.$emit("close");
        },

        translate(item) {
            const translations = {
                asset: "Tillgångskonto",
                debt: "Skuldkonto",
                cost: "Kostnadskonto",
                revenue: "Vinstkonto",
            };
            return translations[item];
        },
        translateSubLedger(item) {
            const translations = {
                none: "Ingen",
                customer: "Kundreskontra",
                supplier: "Leverantörsreskontra",
                inventory: "Lager",
            };
            return translations[item];
        },
    },
};
</script>
